import React, { useState, useEffect, useCallback, useLayoutEffect, useRef } from 'react'
import _ from 'lodash'
import expr from 'aexpr'

function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0,
  })
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    // Add event listener
    window.addEventListener("resize", handleResize)
    // Call handler right away so state gets updated with initial window size
    handleResize()
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize)
  }, []) // Empty array ensures that effect is only run on mount
  return windowSize
}

const InputControl = ({ label, type, value, onChange, min, max, step }) => (
  <>
    <dt>{label}:</dt>
    <dd>
      <input type={type} value={value} onChange={onChange} min={min} max={max} step={step} />
      <input type="number" value={value} onChange={onChange} style={{ maxWidth: 40 }} step={step}/>
    </dd>
  </>
)

const primes = new Set([2, 3, 5, 7, 11, 13, 17, 19, 23, 29, 31, 37, 41, 43, 47, 53, 59, 61, 67, 71, 73, 79, 83, 89, 97])

function isPrime(num) {
  if (num <= 1) return false
  if (num <= 100) return primes.has(num)

  // Check against known primes
  for (const prime of primes) {
    if (num % prime === 0) return false
  }

  // Square root optimization
  let i = 101 // Start from 101 as lower values are already in the 'primes' set
  const sqrt = Math.sqrt(num)
  while (i <= sqrt) {
    if (num % i === 0) return false
    i += 2 // Only check odd numbers as even numbers are not prime (except 2)
  }
  return true
}

const colorize = (value = 0, tests:any = []) => {
  return tests.reduce((color, test) => test(value, color), void 0)
}

function App() {
  const [startDistance, setStartDistance] = useState(100)
  const [angleIncrement, setAngleIncrement] = useState(0.13)
  const [radiusIncrement, setRadiusIncrement] = useState(0)
  const [distanceIncrement, setDistanceIncrement] = useState(1)
  const [initialRadius, setInitialRadius] = useState(5)
  const [count, setCount] = useState(500)
  const [svgWidth, setSvgWidth] = useState(500)
  const [svgHeight, setSvgHeight] = useState(500)
  const [expression, setExpression] = useState('angle')
  const [primeColor, setPrimeColor] = useState("#ff00ff")
  const [evenColor, setEvenColor] = useState("#ffff00")
  const [oddColor, setOddColor] = useState("#00ffff")
  const ref = useRef(null)

  const size = useWindowSize()
  useLayoutEffect(() => {
    if(ref.current) {
      const { width, height } = ref.current.getBoundingClientRect()
      setSvgWidth(width)
      // setSvgHeight(height)
    }
  }, [ref, size])

  const createSpiral = useCallback(() => {
    const center = { x: svgWidth / 2, y: svgHeight / 2 }
    const circles:any = []
    let radius = initialRadius
    let angle = 0
    const getAngleIncrement = (i) => {
      try {
        return expr(expression, { i, angleIncrement, angle: angleIncrement, pi: Math.PI, Math })
      } catch(e) {
        console.error(e)
        return angleIncrement
      }

    }
    for (let i = 0 ; i < count ; i++) {
        const distanceFromCenter = (i + startDistance) * distanceIncrement
        const cx = center.x + distanceFromCenter * Math.cos(angle + startDistance * getAngleIncrement(i))
        const cy = center.y + distanceFromCenter * Math.sin(angle + startDistance * getAngleIncrement(i))
        circles.push(
            <circle key={i} cx={cx} cy={cy} r={radius} fill={colorize(i, [
              (i, p) => i % 2 == 0 ? evenColor : oddColor,
              (i, prevColor) => isPrime(i) ? primeColor : prevColor
            ])} >
                <title>{i}</title>
            </circle>
        )
        angle += getAngleIncrement(i)
        radius += radiusIncrement
    }

    return (
        <svg width={svgWidth} height={svgHeight}>
            {circles}
        </svg>
    )

}, [expression, count, svgWidth, svgHeight, startDistance, angleIncrement, radiusIncrement, distanceIncrement, initialRadius, evenColor, oddColor, primeColor])

  const [svg, setSvg] = useState(createSpiral())

  useEffect(() => {
    setSvg(createSpiral())
  }, [createSpiral])

  return (
    <div style={{ display: 'flex', background: 'black', color: 'white', padding: 10, gap: 10}}>
      <dl style={{ display: 'flex', flexDirection: 'column', flex: 1}}>

        <InputControl label="Start Distance" type="range" value={startDistance} onChange={(e) => setStartDistance(+e.target.value)} min="0" max="500" step="0.01" />
        <dt>Angle expression:</dt>
        <dd><input type="text" value={expression} onChange={_.throttle((e) => setExpression(e.target.value), 100, { leading: true })}/></dd>

        <InputControl label="Angle Increment" type="range" value={angleIncrement} onChange={(e) => setAngleIncrement(+e.target.value)} min="0" max="10" step="0.01" />
        <InputControl label="Radius Increment" type="range" value={radiusIncrement} onChange={(e) => setRadiusIncrement(+e.target.value)} min="0" max="0.1" step="0.001" />
        <InputControl label="Distance Increment" type="range" value={distanceIncrement} onChange={(e) => setDistanceIncrement(+e.target.value)} min="0" max="1" step="0.01" />
        <InputControl label="Initial Radius" type="range" value={initialRadius} onChange={(e) => setInitialRadius(+e.target.value)} min="0" max="5" step="0.1" />
        <InputControl label="Count" type="range" value={count} onChange={(e) => setCount(+e.target.value)} min="0" max="5000" step="1" />
        <InputControl label="Width" type="range" value={svgWidth} onChange={(e) => setSvgWidth(+e.target.value)} min="100" max="5000" step="1" />
        <InputControl label="Height" type="range" value={svgHeight} onChange={(e) => setSvgHeight(+e.target.value)} min="100" max="5000" step="1" />

        <dt>Prime Color:</dt>
        <dd><input type="color" value={primeColor} onChange={_.debounce((e) => setPrimeColor(e.target.value), 100, {leading: true})} /></dd>
        <dt>Even Color:</dt>
        <dd><input type="color" value={evenColor} onChange={_.debounce((e) => setEvenColor(e.target.value), 100, {leading: true})} /></dd>
        <dt>Odd Color:</dt>
        <dd><input type="color" value={oddColor} onChange={_.debounce((e) => setOddColor(e.target.value), 100, {leading: true})} /></dd>
      </dl>
      <div style={{flex: 2, width: '100%', justifyContent: 'center', alignItems: 'center'}} ref={ref}>{svg}</div>
    </div>
  )
}

export default App
